<template>
  <div>
    <div class="d-flex flex-column flex-root">
      <div class="d-flex justify-content-center">
        <div
          id="contentLoader"
          class="bsloader-container"
          style="display:none;z-index: 2!important;left: auto!important;"
        >
          <div class="bsprogress bsfloat bsshadow">
            <div class="bsprogress__item"></div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row flex-column-fluid page">
        <MenuAdmin />
        <div
          class="d-flex flex-column flex-row-fluid wrapper "
          id="contentView"
        >

          <div class="header header-fixed shadow" style="background: #354777;">
            <img src="@/assets/wave3.png" style="
                position: absolute;
                height: 60px;
                width: 100%;
                top: 55px;
              " alt="">
            <div class="container-fluid d-flex align-items-stretch justify-content-between">
              <div class="d-none d-lg-flex align-items-center mr-3">
                <!--begin::Aside Toggle-->
                <button
                  class="btn btn-icon aside-toggle ml-n3 mr-10"
                  @click="sideMenu"
                >
                  <span class="svg-icon svg-icon-xxl svg-icon-white">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Text/Align-left.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="4"
                          y="5"
                          width="16"
                          height="2"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="4"
                          y="13"
                          width="16"
                          height="2"
                          rx="1"
                        />
                        <path
                          d="M5,9 L13,9 C13.5522847,9 14,9.44771525 14,10 C14,10.5522847 13.5522847,11 13,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 Z M5,17 L13,17 C13.5522847,17 14,17.4477153 14,18 C14,18.5522847 13.5522847,19 13,19 L5,19 C4.44771525,19 4,18.5522847 4,18 C4,17.4477153 4.44771525,17 5,17 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </button>
                <!--end::Aside Toggle-->
                <!--begin::Logo-->
                <a href="#">
                  <img
                    alt="Logo"
                    src="@/assets/logo_pu.jpg"
                    class="logo-sticky max-h-35px zn-icon-shadow"
                    style="box-shadow: none"
                  />
                </a>
                <div
                  class="ml-4"
                  style="display: inline-block;"
                >
                  <span
                    class="zn-text-logo"
                    style="display: block;"
                  >Loan Management System</span>
                  <span style="display: block;margin-left: 8px;color: #ffc928;font-size: 11px;
                    font-weight: 400;">"Direktorat Jenderal Sumber Daya Air" </span>
                </div>
                <!--end::Logo-->
              </div>
              <div class="topbar">
                <div
                  class="mr-5 mt-7"
                  style="display: inline-block;text-align: right;"
                >
                  <span
                    class="zn-text-logo"
                    style="display: block;font-size: 12px;"
                  > {{ getMyName }}</span>
                  <span style="display: block;margin-left: 8px;color: #ffc929;font-size: 11px;font-weight: 400;"> {{ getMyIdentity }} </span>
                </div>
                <!--begin::User-->
                <div class="topbar-item mr-4">
                  <div
                    class="btn btn-icon btn-sm btn-clean mr-4 mt-2"
                    @click="G_showProfileView();"
                  >
                    <span class="svg-icon svg-icon-lg svg-icon-white ">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <span class="switch switch-sm switch-outline switch-icon switch-success zn-switch">
                    <label>
                      <input
                        id="setThemeDark"
                        @change="G_darkTheme"
                        type="checkbox"
                        name="select"
                      >
                      <span></span>
                    </label>
                  </span>
                </div>
                <!--end::User-->
              </div>
            </div>
          </div>
          <div class="content d-flex flex-column flex-column-fluid">

            <div class="container-fluid">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>

    <NavProfile />

    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import MenuAdmin from "@/components/admin/MenuAdmin.vue";
import NavProfile from "@/components/admin/NavProfile.vue";

export default {
  name: `LayoutAdmin`,
  components: {
    MenuAdmin,
    NavProfile,
  },
  data() {
    return {
      user: null,
      tooggle: false,
    };
  },
  created() {},
  computed: {
    getMyName: function () {
      const myUser = this.getUser;
      if (myUser) {
        return myUser.name;
      }

      return "";
    },
    getMyIdentity: function () {
      const myUser = this.getUser;
      if (myUser) {
        return (
          (this.getUser.userGroup ? this.getUser.userGroup : "") +
          " " +
          (this.getUser.kdbalai ? "| " + this.getUser.kdbalai : "") +
          " " +
          (this.getUser.kdsatker ? "| " + this.getUser.kdsatker : "")
        );
      }

      return "";
    },
  },
  methods: {
    sideMenu() {
      if (this.tooggle == false) {
        $(".content").css("margin-left", "230px");
        $(".header-fixed .header").css("left", "230px");
        $(".aside").css("left", "0");
        this.tooggle = true;
      } else {
        $(".content").css("margin-left", "0px");
        $(".header-fixed .header").css("left", "0px");
        $(".aside").css("left", "-295px");

        this.tooggle = false;
      }
    },
  },
  mounted() {
     $(".content").css("margin-left", "230px");
      $(".header-fixed .header").css("left", "230px");
      $(".aside").css("left", "0");
      this.tooggle = true;
    this.G_darkThemeInit();
  },
};
</script>