<template>


    <div class="aside aside-left d-flex flex-column flex-row-auto" id="kt_aside" >
        <!--begin::Aside Menu-->
        <perfect-scrollbar>
        <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
            <!--begin::Menu Container-->
            <div id="kt_aside_menu" class="aside-menu min-h-lg-800px" data-menu-vertical="1" data-menu-scroll="1"
                data-menu-dropdown-timeout="500">
                <!--begin::Menu Nav-->
                <button style="display: none;font-size: 6px;position: absolute;right: 10px;top: 10px;"
                    class="btn btn-icon btn-light-danger btn-sm" id="kt_aside_close_btn"><i
                        class="la la-close"></i></button>

                <ul class="menu-nav p-1 pt-5">

                    <v-list flat>

                        <template v-for="(m,index) in menu">
                            <v-list-item v-if="m.url" :to="m.url" :key="index">
                                <v-list-item-icon>
                                    <v-icon :color="SetColor[index]" style="margin-right:15px;font-size:22px !important;" v-text="m.iconCss"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="m.menuNm"></v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>

                            
                            <v-list-group v-else :key="index" color="info" :value="(m.menuNm == 'Dashboard Kinerja PHLN') ? true:false" no-action >
                            
                                <template v-slot:activator>
                                    <v-list-item-icon>
                                    <v-icon :color="SetColor[index]" style="margin-right:15px;font-size:22px !important;" v-text="m.iconCss"></v-icon>
                                </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="m.menuNm"></v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item-group                                                                                                                                                                                                       v-model="selectedItem" color="info">

                                    <template v-for="(c,ic) in m.child">
                                        <v-list-item v-if="!c.child" :to="c.url" :key="index+ic">
                                            <!-- <router-link :to="c.url" :id="'sb-'+c.menuNmUid" class="sb- menu-link"> -->
                                            <v-list-item-icon>
                                                <v-icon :color="SetColor[index]" style="margin-left:40px;margin-right:5px;margin-top:2px;font-size:12px !important;">mdi-checkbox-blank-circle-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="c.menuNm"></v-list-item-title>
                                            </v-list-item-content>
                                            <!-- </router-link> -->
                                        </v-list-item>

                                        <v-list-group v-else :key="index+ic" :color="SetColor[index]" :prepend-icon="c.iconCss"
                                            no-action sub-group>
                                            <template v-slot:activator>
                                                
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="c.menuNm"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>

                                            <v-list-item-group v-model="selectedItem" color="info">


                                                <v-list-item :to="sub.url" v-for="(sub,icsub) in c.child"
                                                    :key="index+ic+icsub">
                                                    <!-- <router-link :to="c.url" :id="'sb-'+c.menuNmUid" class="sb- menu-link"> -->
                                                   <v-list-item-icon>
                                                        <v-icon :color="SetColor[index]" style="margin-left:40px;margin-right:5px;margin-top:2px;font-size:12px !important;">mdi-checkbox-blank-circle</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="sub.menuNm"></v-list-item-title>
                                                    </v-list-item-content>
                                                    <!-- </router-link> -->
                                                </v-list-item>


                                            </v-list-item-group>

                                        </v-list-group>
                                    </template>


                                </v-list-item-group>

                            </v-list-group>

                        </template>


                    </v-list>



                </ul>
                <!--end::Menu Nav-->
            </div>
            <!--end::Menu Container-->
        </div>
        <!--end::Aside Menu-->
        </perfect-scrollbar>
    </div>
</template>

<script>
    export default {
        name: 'MenuAdmin',
        data() {
            return {
                activeItem:true,
                menu: null,
                tooggle: false,
                selectedItem: 1,
                SetColor:['red','success','info','warning','red','success','info','warning','red','success','info','warning'],
                urlPath: window.location.pathname.split('/')[1]
            };
        },
        mounted() {
            this.G_GetAny('menu').then(res => {
                this.menu = res.data
            })
        },
        methods: {
            log(item) {
            },


        }
    }
</script>