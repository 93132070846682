<template>
<div>
    <div id="kt_quick_user" class="offcanvas offcanvas-left p-10">

        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 class="m-0">Profile
                <small class="text-muted font-size-sm ml-2"></small></h3>
            <button @click="G_hideProfileView()" class="btn btn-xs btn-icon btn-light btn-hover-primary"
                id="kt_quick_user_close">
                <i class="ki ki-close icon-xs text-muted"></i>
            </button>
        </div>
        <div class="offcanvas-content pr-5 mr-n5">
            <div class="d-flex align-items-center mt-5">
                <div class="symbol symbol-100 mr-5">
                    <i class="flaticon-user zn-profile-icon"></i>
                </div>
                <div class="d-flex flex-column">
                    <a href="#" class="font-size-h5 text-dark-75 text-hover-primary">{{getUser.name}}</a>
                    <div class="text-muted mt-1">{{getUser.usersTypes[0]}} | {{getUser.userGroup}}</div>

                </div>
            </div>
            <div>
                <div class="navi mt-5">
                    <button type="button" @click="changePasswordShow" class="btn btn-sm btn-primary font-weight-bolder mr-2">Change Password</button>
                    <button type="button" @click="G_Logout" class="btn btn-sm btn-light-primary font-weight-bolder ">Sign Out</button>
                </div>
            </div>
            <div class="separator separator-dashed mt-8 mb-5"></div>
        </div>
      
    </div>
      <div class="offcanvas-overlay" style="display:none"></div>
     <G-dialogModal ref="modal">
        <div slot="content">
                <v-form ref="form" id="formData" lazy-validation v-on:submit.prevent="changePassword_store" >
                      <div class="row mt-4">
                        <div class="col-md-12">
                        <G-password label="Password Lama" name="oldPassword" icon="la la-lock" :validation="['required']" /> 
                        <G-password label="Password Baru" name="newPassword" icon="la la-lock" :validation="['required']" /> 
                        <!-- <G-password label="Ketik Ulang Password Baru" name="confirmPassword" icon="la la-lock" :validation="['required']" />  -->
                        </div>
                    </div>
                </v-form>
          
        </div>
        <div slot="action">
            <v-spacer></v-spacer>
              <v-btn color="success" rounded depressed @click.native="changePassword_store">
                  Simpan
              </v-btn>
        </div>
    </G-dialogModal>
    <G-dialogConfirm ref="confirm"/>
</div>
    
</template>
<script>
 export default {
        methods: {
            changePasswordShow(){
                 if (this.$refs.form) {
                    this.$refs.form.resetValidation()        
                }
                this.G_hideProfileView()
                this.$refs.modal.open("Ubah Password",400)
            },
            async changePassword_store(){
               
                if (this.$refs.form.validate()) {
                    let myForm = document.getElementById('formData');
                    let formData = new FormData(myForm); 
                    
                    // if (formData.get('newPassword') != formData.get('confirmPassword')) {
                    //      this.$snotify.warning('Password Baru dan Ketik Ulang Password Baru Tidak Sama !');
                        
                    // }else{
                         let judul = "Ubah Password"
                        let subJudul = "Yakin Akan Ubah Password ?"

                        if (await this.$refs.confirm.open(judul,subJudul) ) {
                            this.$refs.confirm.loading()
                              this.G_PostAny('user/changePassword', formData).then(res => {
                                console.log(res);
                                if (res.status) {
                                    this.$snotify.warning(res.data.message);
                                   
                                }else{
                                     this.$refs.confirm.close()
                                      this.$refs.modal.close()
                                    this.$snotify.success(res.message);
                                }
                            })
                            .finally(() => {
                                this.$refs.confirm.loadingEnd()
                            }); 
                        }
                    // }
                    
                    
                }
            }
            
        },

    }
</script>